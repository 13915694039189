.budget {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;

  &-mlist {
    position: relative;
    margin: 2rem auto;
    border: 2px gray solid;
    border-radius: 0.7rem;

    &-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid gray;
      padding: 6px 1rem;
      font-weight: bold;

      &-shift,
      &-jobname,
      &-date,
      &-addicon {
        margin: auto 0;
      }

      &-editrow {
        cursor: pointer;
        margin: auto 0;

        &-icon {
          margin-left: 1rem;

        }
      }

      &-addrow {
        cursor: pointer;

        &-icon {
          margin-left: 1rem;
        }
      }


    }

    &-materialdesc {
      position: relative;
      font-size: 1.6rem;
      background-color: #DCDCDC;

      &-add {

        &-icon {
          position: inherit;
          height: 2rem;
          width: 2rem;
          margin: 0 2rem 2rem 90rem;

        }
      }

      &-header-rows-item {
        background-color: #FB918F;
      }

      .tdMatQty {
        width: 10%;
      }

      .tdUnitCost {
        width: 10%;
      }

      .tdMatDesc {
        width: 30%;

        .matDescInput {
          overflow-wrap: break-word;
        }
      }

      .tdTotalCost {
        width: 10%;
      }

      .tdMatUM {
        width: 10%;
      }

      &-body-container-item {
        background-color: #ffffff;
      }
    }
  }
}