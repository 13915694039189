@import '../..//sass/variables';

.homescreen {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5%;

  &-menu-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: $btn-color-primary;
    height: 30rem;
    width: 30rem;
    margin-top: 5rem;
    color: #000000;
    text-decoration: none;
    cursor: pointer;

    &-abrev {
      font-size: 3rem;
      margin: 0.5rem 0 0 0.5rem
    }

    &-title {
      position: relative;
      display: flex;
      flex-direction: column;
      top: 7rem;

      &-text {
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
      }


    }

  }


}