.wr {
  position: relative;

  &-btngrp {
    position: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 3rem 0;

    &-recallbtn {
      position: inherit;
      margin: 0 2rem 0 5.5rem;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-jobimagesbtn {
      position: inherit;
      margin: 0 2rem 0 auto;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-printbtn {
      position: inherit;
      margin-right: 5.5rem;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-printbtn:hover {
      background-color: #F0F0F0;
    }

    &-editselectedlbtn {
      position: inherit;
      margin-right: 2rem;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-editselectedlbtn:hover {
      background-color: #F0F0F0;
    }

    &-saveeditlbtn {
      position: inherit;
      margin-right: 2rem;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-saveeditlbtn:hover {
      background-color: #F0F0F0;
    }

  }


  &-container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 4rem 3rem;

    &-reports {
      position: relative;
      width: 60rem;
      min-width: 24rem;
      border: 0.1rem solid #000000;
      border-radius: .7rem;
      background-color: #D9D9D9;
      height: 55.3rem;
      overflow-y: auto;
      font-size: 1.4rem;
      margin-top: 4rem;

      &-title {
        position: relative;
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-list {
        position: relative;
        list-style: none;


        &-item {
          margin-top: 1rem;
          cursor: pointer;
          background-color: #b5b5b5;
          height: 8rem;
          padding: 1.3rem 0 1.3rem 2rem;
          font-size: 1.4rem;

          &-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-title {
              font-size: 1.4rem;
            }
          }
        }

        .itemselected {
          background-color: #F0F0F0;
        }

        &-item:hover {
          background-color: #F0F0F0;
        }
      }
    }
  }
}