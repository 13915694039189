@import '../../sass/variables';

.eid {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #E7E5E5;
  padding: 2rem;

  &-employeeinfo {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 4rem auto 3rem auto;

    &-dropdown {
      position: relative;
      display: flex;
      align-items: center;

      &-label {
        margin-right: 1rem;
      }
    }

    &-details {
      position: relative;
      display: flex;
      flex-direction: column;

      &-name,
      &-class {
        position: relative;
        display: flex;

        &-label {
          margin-right: 1rem;
        }
      }
    }
  }

  &-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70rem;

    &-group {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-capturebtn {
        height: 3rem;
        width: 12rem;
        background-color: #30ABE6;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        margin-top: 3rem;
      }

      &-retakegrp {
        position: relative;
        display: flex;
        justify-content: space-evenly;

        &-retakebtn {
          height: 3rem;
          width: 12rem;
          background-color: #30ABE6;
          color: #ffffff;
          border: none;
          border-radius: 5px;
          margin-top: 3rem;
        }

        &-savebtn {
          height: 3rem;
          width: 12rem;
          background-color: $btn-color-green;
          color: #000000;
          border: none;
          border-radius: 5px;
          margin-left: 5rem;
          margin-top: 3rem;
        }
      }


    }
  }
}