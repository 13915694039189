@import '../../../sass/variables';

.pdfattachments {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  &-header {
    margin: 2rem auto;
  }

  &-controls {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 4rem;

    &-selectbtn {
      height: 3rem;
      font-size: 1.2rem;
      width: 10rem;
      margin-right: 1rem;
    }

    &-deletebtn {
      height: 3rem;
      font-size: 1.2rem;
      width: 12rem;
    }
  }


  &-listcontainer {
    position: relative;
    margin: 2rem auto;
    padding: 0 2rem;
    overflow-y: scroll;


    &-list {
      position: relative;
      display: flex;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 0 5rem;


      &-item {
        margin: 2rem auto 2rem 1rem;
        border: 3px solid $grey-primary;
        padding: 0 2px;
        border-radius: 3px;
        cursor: pointer;

        &-doc {


          &-document {

            &-page {}
          }
        }
      }

      &-item:nth-last-of-type(2) {
        margin: 2rem 5.3rem 2rem 1rem;
      }

      .selectedpdf {
        border: 3px solid $btn-admin-secondary;
        background-color: $btn-admin-secondary;
        color: #ffffff;
      }
    }
  }

  &-detailscontainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-controls {
      position: relative;
      display: flex;
      width: 100%;
      margin: 1rem auto;

      &-backbtn {
        height: 3rem;
        font-size: 1.2rem;
        width: 8rem;
      }
    }

    &-header {
      width: 100%;
      margin: 1rem auto;

      &-name,
      &-jobname,
      &-notes {

        &-label {
          font-weight: bold;
        }
      }
    }
  }
}