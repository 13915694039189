@import '../../../sass/variables';

.registeruser {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 1.8rem;
  background-color: #e0dfda;

  &-confirmation {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    &-header1 {
      position: relative;
      text-align: center;
      margin: 2rem 0 3rem 0;
    }

    &-header2 {
      position: relative;
      text-align: center;
      margin: 1rem 0 8rem 0;
    }

    &-message {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-header {
    position: relative;
    text-align: center;
    margin: 2rem 0 2rem 0;
    // top: 12rem;

    &-title {
      margin-bottom: 2rem;
    }
  }

  &-registerationtype {
    position: relative;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 9rem;
    margin: 5rem 0 5rem 0;
    width: 50rem;

    &-label {
      height: 3rem;
      // text-align: center;
    }

    &-menu {
      height: 3rem;
      font-size: 1.8rem;
      width: 50%;
      border-radius: 0.7rem;
      cursor: pointer;
    }

    &-submitbtn {
      width: 20rem;
      height: 5rem;
      border-radius: 0.7rem;
      margin: 10rem auto 0 auto;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }



  &-fieldform {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 6rem;

    &-editbtn {
      width: 6rem;
      height: 3rem;
      font-size: 1.8rem;
      cursor: not-allowed;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      margin: 2rem 2rem 1rem auto;
    }

    &-editbtn:hover {
      background-color: #F0F0F0;
    }

    .editbtnactive {
      cursor: pointer;
    }

    &-row1 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      padding: 0 3rem;

      &-firstName,
      &-lastName {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 40%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }
    }

    &-row2 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      padding: 0 3rem;

      &-middleInitial,
      &-class,
      &-last4SSN {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 20%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }

      &-level {
        // width: 10rem;

        &-menu {
          width: 6rem;
          height: 3rem;
          cursor: pointer;
        }
      }

      &-email {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 35%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }

      &-phone {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 35%;
        margin-left: 3rem;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;

        }
      }
    }

    &-row3 {
      position: relative;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      padding: 0 3rem;

      &-email {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 35%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }

      &-phone {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 35%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }
    }

    &-row4 {
      position: relative;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      padding: 0 3rem;

      &-phone {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 35%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }
    }

    &-btngroup {
      position: relative;
      display: flex;
      flex-direction: row;

      &-savebtn {
        width: 6rem;
        height: 3rem;
        font-size: 1.8rem;
        cursor: pointer;
        border-radius: 0.5rem;
        border: 0.1rem solid #000000;
        background-color: #b5b5b5;
        margin: 4rem 2rem 0 2rem;

      }

      &-savebtn:hover,
      &-savebtn:active {
        background-color: #F0F0F0;
      }
    }
  }

  &-authform {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 6rem;
    margin-top: 5rem;

    &-editbtn {
      width: 6rem;
      height: 3rem;
      font-size: 1.8rem;
      cursor: not-allowed;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      margin: 2rem 2rem 1rem auto;
    }

    &-editbtn:hover {
      background-color: #F0F0F0;
    }

    .editbtnactive {
      cursor: pointer;
    }

    &-row1 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      padding: 0 3rem;

      &-userName,
      &-password {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 40%;

        &-input {
          width: 100%;
          height: 4rem;
          font-size: 1.8rem;
          border: none;
        }
      }
    }

    &-btngroup {
      position: relative;
      display: flex;
      flex-direction: row;

      &-savebtn {
        width: 6rem;
        height: 3rem;
        font-size: 1.8rem;
        cursor: pointer;
        border-radius: 0.5rem;
        border: 0.1rem solid #000000;
        background-color: #b5b5b5;
        margin: 4rem 2rem 0 2rem;

      }

      &-savebtn:hover,
      &-savebtn:active {
        background-color: #F0F0F0;
      }
    }
  }

}