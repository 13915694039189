@import '../../sass/variables';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 0.1rem #000000 solid;
  z-index: 9999;
  color: #000000;
  background-color: #ffffff;
}

.nav {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 3%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 3%;
  font-size: 1.5rem;


  &-logout {
    background-color: $btn-admin-primary;
    color: #ffffff;
    width: 7rem;
    cursor: pointer;
    font-size: 1.8rem;
    text-align: center;
    padding: 0.6rem 0 0.6rem 0;
    border-right: #133561 0.1rem solid;
  }

  &-logout:active,
  &-logout:hover {
    background-color: $btn-admin-secondary;
  }


  &-menu {
    background-color: $btn-color-primary;
    width: 8rem;
    height: 8rem;
    text-align: center;
    padding: 2rem 0 2rem 0;
    font-weight: 600;
    font-size: 3.5rem;
    cursor: pointer;

    &-icon {
      height: 4rem;
      width: 4rem;
      margin-left: auto;
    }

    &-container {
      background-color: #D9D9D9;
      z-index: 9999;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 80rem;
      width: 20rem;
      box-shadow: 0.1rem 0.1rem 2rem #8C8C8C;
      overflow-y: scroll;

      &-cancel {
        position: relative;
        height: 3rem;
        width: 3rem;
        margin: 0.5rem 0.5rem 0.5rem auto;
      }

      &-list {
        position: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        top: 3rem;

        &-item {
          background-color: #ffffff;
          height: 5rem;
          width: 12.5rem;
          text-align: center;
          font-size: 2.6rem;
          font-weight: 600;
          padding: 1.5rem 0;
          margin: 1.5rem 0;
          cursor: pointer;

        }
      }

      &-logoutBtn {
        position: relative;
        margin: 72rem 0 3rem 0;
        cursor: pointer;
      }
    }

    .containersmall {
      background-color: magenta;
    }

  }


}