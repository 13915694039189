.laborbreakdown {
  position: relative;
  display: flex;
  flex-direction: column;

  &-printcontainer {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &-btn {
      position: inherit;
      margin-right: 5.5rem;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-btn:hover {
      background-color: #F0F0F0;
    }
  }

  &-headercontainer {
    text-align: center;
    margin: 2rem auto;
  }

  &-table {
    border: 1px solid #b5b5b5;

    &-body {
      border: none;

      .roweven {
        background-color: #b5b5b5;
      }

      &-row {



        &-col {
          border: none;
        }
      }
    }

  }
}