.laborbreakdown {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  &-laborcodebreakdown {
    margin: 2rem 0 2rem 0;

    &-table {
      width: 80%;
      border: 1px solid #000000;

      &-b {
        background-color: lightgray;

        &-row {

          &-item {
            text-align: center;
          }
        }
      }
    }

  }

  &-edit {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 2rem 0;

    &-btn {
      margin-right: 5rem;
      cursor: pointer;
    }
  }

  &-table {

    &-head {
      border: 5px solid #000000;
    }

    &-body {
      &-row {

        &-col {
          text-align: center;
          border: #000000 solid 0.5px;
        }

        &-editinput {
          margin: 0;
          width: 50%;
        }
      }
    }
  }

  &-labortotal {
    padding: 2rem;

    &-text {
      font-weight: bold;
    }
  }
}