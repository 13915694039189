

@import './sass/variables.scss';

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1rem = 10px;  10px/16px = 62.5%

  // @include respond(tab-landscape) { // width < 1200?
  //   font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%
  // }

  // @include respond(tab-port) { // width < 900?
  //   font-size: 50%; // 1rem = 9px, 8/16 = 50%
  // }

  // @include respond(desktop) {
  //   font-size: 75%; // 1rem = 12, 12/16 = 75%
  // }
}

body {
  box-sizing: border-box;
  // padding: 3rem;
  font-family: $body-font;
}