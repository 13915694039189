@import '../../../sass/variables';

.checklist {
  position: relative;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  // color: #ffffff;
  font-size: 1.5rem;

  &-blankfields {
    &-add {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-items: center;
      padding: 0 5rem;
      margin: 0 0 5rem 0;

      &-btn {
        position: relative;
        font-size: 1.5rem;
        width: 6rem;
        height: 2rem;
        border-radius: 0.5rem;
        cursor: pointer;
        margin: 0 5rem 0 auto;
      }

      &-btn:active,
      :hover {
        background-color: #b5b5b5;
      }

      &-icon {
        position: inherit;
        height: 2rem;
        width: 2rem;
        cursor: pointer;
      }
    }
  }

  &-submitbtn {
    margin: 4rem 0 5rem 5rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  &-submitbtn:active {
    background-color: #b5b5b5;
  }

  &-topform {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem 7rem;
    width: 100%;

    &-checklisttype {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      // margin-bottom: 2rem;

      &-select {
        height: 3rem;
        border: none;
        font-size: 1.8rem;
        text-align: center;
      }
    }

    &-inputgroup {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      margin-top: 2rem;

      &-label {
        margin-bottom: 2rem;
        width: 10rem;
      }

      &-input {
        width: 50%;
        height: 3rem;
        padding: 1rem;
      }
    }
  }

  &-fieldtasks {
    position: relative;
    padding: 2rem;

    &-items {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      &-number {
        width: 3rem;
        height: 3rem;
        margin-right: 2rem;
      }

      &-question {
        width: 75%;
        height: 3rem;

        &-removewrapper {
          position: relative;
          border-radius: 5rem;
          width: 2rem;
          height: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 0.1rem solid #000000;
          cursor: pointer;
        }
      }

    }
  }

}