@import '../../sass/variables';

.adminmenu {
  position: relative;
  width: 100%;
  font-size: 1.8rem;

  &-links {

    &-list {
      position: relative;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      z-index: 9999;
      height: 6rem;

      &-item {
        text-decoration: none;
        border-radius: 2rem;

        &-link {
          color: #ffffff;
          width: 15rem;
          height: 3rem;
          padding: 0.5rem 0 0.2rem 0;
          text-align: center;
          // border: 0.1rem solid #000000;
          border-radius: 2rem;
          background-color: $btn-admin-primary ;
          cursor: pointer;
        }

        &-link:hover {
          background-color: $btn-admin-secondary;
          color: #ffffff
        }

        .selecteditem {
          background-color: $btn-admin-secondary;
          // color: #000000;
          color: #ffffff
        }
      }


    }
  }


}