@import '../../sass/variables';

.workreport {
  position: relative;
  height: 100vh;
  width: 100vw;


  // .menubtn {
  //   position: inherit;
  //   margin-left: 80rem;
  // }

  &-sidemenu {
    position: absolute;
    z-index: 6;
    height: 85rem;
    width: 40rem;
    border-top: 0.1rem solid #000000;
    border-left: 0.1rem solid #000000;
    border-bottom: 0.1rem solid #000000;
    background-color: #D9D9D9;
    left: 58rem;

    &-closeMenu {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      top: 3rem;

      &-btn {
        position: relative;
      }
    }

    &-menulist {
      list-style: none;
      font-size: 1.8rem;

      &-item {
        background-color: #ffffff;
        height: 8rem;
        margin: 2rem 0;
        text-align: center;
        padding: 2.5rem 0;
      }
    }
  }

  &-main {
    // overflow-y: scroll;

    &-section-divider {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 0 0 2rem;
      border-top: 1px solid #000000;
      margin: 2rem 0 1rem 0;
      // width: 100vw;
      left: 0;
      right: 0;

      &-icon {
        position: relative;
        height: 2rem;
      }

      &-title {
        position: relative;
        font-size: 1.8rem;
        margin-left: 1.5rem;
      }
    }

    &-changeordertitle {
      position: relative;
      display: flex;
      flex-direction: column;
      font-size: 1.8rem;
      padding: 0 2rem 0 2rem;

      &-label {
        margin: 1rem 0 2rem 0;
      }

      &-input {
        height: 5rem;
        border-radius: 0.5rem;
        padding: 0 1rem;
        font-size: 1.8rem;
      }
    }

    &-materialdesc {
      position: relative;
      width: 100vw;
      font-size: 1.6rem;
      background-color: #DCDCDC;

      &-add {
        position: relative;
        display: flex;

        &-icon {
          position: inherit;
          height: 2rem;
          width: 2rem;
          margin: 0 2rem 2rem auto;

        }
      }

      &-header-rows-item {
        background-color: #FB918F;
      }

      .tdMatQty {
        width: 10%;
      }

      .tdUnitCost {
        width: 10%;
      }

      .tdMatDesc {
        width: 30%;

        .matDescInput {
          overflow-wrap: break-word;
        }
      }

      .tdTotalCost {
        width: 10%;
      }

      .tdMatUM {
        width: 10%;
      }

      .remove {
        width: 10%;

        &-iconcontainer {
          position: relative;
          display: flex;

          &-icon {
            width: 3rem;
            height: 3rem;
            cursor: pointer;
            margin: 0 auto;
          }
        }



      }



      &-body-container-item {
        background-color: #ffffff;
      }
    }

    &-workdesc {
      position: relative;
      margin: 1rem auto 0 auto;
      left: 10rem;
      width: 80%;
      height: 30rem;
      border: 0.2rem solid #000000;
      border-radius: 1rem;
      padding: 3rem;
      font-size: 1.8rem;
      overflow-wrap: break-word;
    }

    &-remarks {
      position: relative;
      margin: 1rem auto 0 auto;
      left: 10rem;
      width: 80%;
      height: 30rem;
      border: 0.2rem solid #000000;
      border-radius: 1rem;
      padding: 3rem;
      font-size: 1.8rem;
      overflow-wrap: break-word;
    }

    &-incident {
      font-size: 1.8rem;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      border-top: 0.2rem solid #000000;
      border-bottom: 0.2rem solid #000000;
      height: 4rem;

      &-textinput {
        position: relative;
        margin: 1rem auto 0 auto;
        left: 10rem;
        width: 80%;
        height: 30rem;
        border: 0.2rem solid #000000;
        border-radius: 1rem;
        padding: 3rem;
        font-size: 1.8rem;
        overflow-y: auto;
        overflow-wrap: break-word;
      }
    }

    &-tasks {
      position: relative;
      top: -1rem;

      &-add {
        position: relative;
        display: flex;

        &-icon {
          position: inherit;
          height: 2rem;
          width: 2rem;
          margin: 0 2rem 1rem auto;

        }
      }


      &-table {
        position: relative;
        height: 4rem;
        width: 100%;
        font-size: 1.8rem;
        margin: 0 auto;

        &-row {
          position: relative;
          background-color: #DCDCDC;

          &-content {
            position: relative;
            width: 12rem;

            &-input {
              width: 75%;
              height: 4rem;
              margin: 0 2rem 0 2rem;
              border: none;
              font-size: 1.5rem;
              overflow-wrap: break-word;
              padding: 0 0.5rem;
            }

            .wrNumberInput {
              width: 50%;
            }

            &-removecontainer {
              position: relative;
              display: flex;

              &-icon {
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                margin: 0 auto;
              }
            }
          }

          &-contentremove {
            position: relative;
            width: 10%;

            &-removecontainer {
              position: relative;
              display: flex;

              &-icon {
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                margin: 0 auto;
              }
            }
          }


        }
      }
    }

    &-approvals {
      position: relative;
      font-size: 1.8rem;
      font-weight: 600;
      display: flex;
      flex-direction: column;

      &-namescontainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 0.2rem solid #000000;
        border-bottom: 0.2rem solid #000000;
        margin: 0.5rem 0 0.5rem 0;
        height: 15rem;
        padding-left: 2rem;

        &-sig {
          position: relative;
          display: flex;
          flex-direction: row;

          &-label {
            margin-right: 2rem;
            width: 20rem;
          }
        }

        &-name {
          position: relative;
          display: flex;
          flex-direction: row;

          &-label {
            margin: 1rem 2rem 0 0;
          }

          &-input {
            height: 3rem;
            width: 50rem;
            border: none;
            cursor: pointer;
            padding-left: 5rem;
            font-size: 1.8rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .wrNumberInputTd {
    width: 15%;
  }

  &-submitBtn {
    position: relative;
    width: 20rem;
    margin: 10rem 0 5rem 2rem;
  }

  &-nodata {
    position: relative;
    font-size: 4rem;
    color: #b5b3aa;
    text-align: center;
    top: 45rem;
  }
}