// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@400;600;700&family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap');

$body-font: 'Inter', sans-serif;

$btn-color-primary: #D9D9D9;
$btn-color-green: #13F044;
$btn-color-magenta: #EC13F0;
$btn-color-blue: #62CDFB;

$btn-admin-primary: #1A4883;
$btn-admin-secondary: #2F7FE9;

$grey-primary: #777878