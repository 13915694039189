.jobimages {
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &-controls {
    position: relative;
    display: flex;
    justify-content: flex-start;

    &-filters,
    &-select {
      margin-right: 2rem;
    }


  }


  &-filters {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0;

    &-header {
      margin: auto 2rem;
      font-size: 1.5rem;
    }

    &-jobselector,
    &-dateselector {
      margin: 0 2rem 0 0;

      &-label {
        margin: 0 1rem 0 0;
      }
    }
  }

  &-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &-imgcontainer {
      border: 0.5px solid #000000;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 25rem;
      width: 16rem;
      padding: 1rem;
      margin: 1rem;
      cursor: pointer;
      margin: 1rem auto;

      &-img {
        height: 10rem;
        width: 12.5rem;
      }

      &-job {
        font-size: 1.2rem;
        margin: 0.5rem auto;
      }

      &-txt {
        font-size: 1rem;
        margin: 0.5rem auto;
      }

      &-date {
        font-size: 1rem;
        margin: 0.5rem auto;
      }
    }
  }

  &-submitbtn,
  &-resetbtn {
    cursor: pointer;
    margin-right: 1rem;
    width: 7rem;
  }

  &-selectedimage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-viewallbtn {
      position: relative;
      height: 4rem;
      width: 15rem;
      text-align: center;
      align-items: center;
      font-weight: bold;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 8px 0;
    }

    &-image {
      height: 50rem;
      width: 65rem;
    }
  }
}