@import '../../sass/variables';

.workers {
  position: relative;

  .select-selected {
    background-color: magenta;
  }

  &-list {
    position: relative;
    list-style: none;

    &-item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      background-color: $btn-color-primary;
      height: 5rem;
      margin-bottom: 2rem;
      cursor: pointer;

      &-name {
        width: 15rem;
        margin-right: 5rem;
        padding-top: 0.5rem;
        font-size: 1.4rem;
      }

      &-costcode {
        width: 7.5rem;
        margin-right: 1rem;
        text-align: center;
        padding-top: 0.4rem;
        font-size: 1.4rem;
        border: 0.1rem solid #000000;
        background-color: $btn-color-primary;
        align-items: center;
        grid-template-areas: "select";

        &-option {
          width: 100%;
        }
      }

      // &-costcode::before,
      // &-costcode::after {
      //   width: 7.5rem;
      //   margin-right: 1rem;
      //   text-align: center;
      //   padding-top: 0.4rem;
      //   font-size: 1.4rem;
      //   border: 0.1rem solid #000000;
      //   background-color: $btn-color-primary;
      // }

      &-last4ssn {
        width: 4rem;
        margin-right: 5rem;
        text-align: center;
        padding-top: 1.5rem;
      }

      &-class {
        width: 12rem;
        margin-right: 5rem;
        text-align: center;
        padding-top: 1.5rem;
        font-size: 1.5rem;
      }

      &-timein,
      &-timeout {
        position: relative;
        width: 15rem;
        margin-right: 5rem;
        background-color: $btn-color-magenta;
        text-align: center;
        cursor: pointer;

        &-time {
          position: relative;
          top: 0.6rem;
          font-size: 1.5rem;
        }

        &-placeholder {
          position: relative;
          top: 1.5rem;
        }
      }

      &-notes {
        width: 12rem;
        text-align: center;
        padding-top: 1.5rem;
        background-color: $btn-color-blue;
        cursor: pointer;
      }

      .employeein,
      .employeeout,
      .employeenotes {
        background-color: $btn-color-green;
      }
    }
  }
}