.clreport-downloadbtn {
  position: relative;
  margin: 5rem 0 0 5rem;
  height: 3rem;
  width: 40rem;
  border-radius: 0.3rem;
  border: 0.1rem solid #000000;
  cursor: pointer;
  background-color: #62BAEA;
  // color: #ffffff;
}

.clreport-downloadbtn:hover,
.clreport-downloadbtn:active {
  background-color: #0C4766;
  color: #ffffff;
}

.clreport {
  position: relative;
  // color: #ffffff; 
  padding: 2rem 5rem 2rem 5rem;
  // width: 100%;
  width: 99.2rem;
  font-size: 1.3rem;

  &-titlegrp {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    font-size: 1.5rem;
    border: 0.2rem solid #000000;
    margin-top: 2cap;

    &-address {
      position: relative;
      border-right: #000000 solid 0.1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &-text {
        position: relative;
        margin-right: 25rem;
      }
    }

    &-clname {
      position: relative;
    }
  }

  &-topcontainer {
    font-size: 1.5rem;

    &-title {
      text-align: center;
      margin-top: 1rem;
    }

    &-row1 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-contractno {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-description {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 100%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }

    &-row2 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-contractor {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-sub_contractor {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }

    &-row3 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-location {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-date {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }

    &-row4 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-preparedBy {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-datePrepared {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 50%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }

    &-row5 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-inspectorname {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 100%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }

    &-row6 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-surveillanceReport {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 60%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-drawing {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 40%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }


  }

  &-fieldtasks {
    position: relative;
    font-size: 1.3rem;
    margin: 2rem 0;

    &-title {
      text-align: center;
    }

    &-list {
      list-style: none;

      &-item {
        margin: 0.5rem 0;

        &-grp {
          position: relative;
          display: flex;
          flex-direction: row;

          &-ans {
            margin-left: auto;
          }
        }
      }
    }
  }

  &-nonconformancesrmks {
    font-size: 1.3rem;
    margin: 2rem 0;
    border: #000000 solid 0.2rem;
    padding: 0.5rem 1.5rem;
    height: 15rem;

    &-label {
      font-weight: bold;
      margin-bottom: 3rem;
    }

    &-text {
      font-size: 1.3rem;
    }
  }

  &-additionalrmks {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    border: #000000 solid 0.2rem;
    padding: 0.5rem 1.5rem;
    height: 15rem;

    &-label {
      font-weight: bold;
      margin-bottom: 3rem;
    }

    &-text {
      font-size: 1.3rem;
    }
  }


  &-sigcontainer {
    font-size: 1.3rem;

    &-title {
      text-align: center;
      margin-top: 1rem;
    }

    &-row1 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-name {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 60%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-title {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 40%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }

    &-row2 {
      position: relative;
      display: flex;
      flex-direction: row;

      &-signature {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 60%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }

      &-date {
        position: relative;
        display: flex;
        flex-direction: row;
        border: #000000 solid 0.1rem;
        width: 40%;

        &-label {
          padding: 1rem;
        }

        &-text {
          padding: 1rem;
        }
      }
    }
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
    visibility: hidden !important;
  }
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: auto;
    visibility: hidden !important;
  }
}