.laborbreakdown {
  position: relative;
  display: flex;
  flex-direction: column;

  &-table {
    padding: 1rem;

    &-head {
      border: 5px solid #000000;
    }

    &-body {
      &-row {

        &-col {
          text-align: center;
        }
      }
    }
  }
}