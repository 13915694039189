@import '../../sass//variables';

.pdfviewer {
  position: fixed;
  bottom: 5%;
  left: 1.4%;
  z-index: 99999;
  background-color: $btn-color-primary;
  border: 0.1rem #000000 solid;
  border-radius: 1rem;
  height: 95rem;
  width: 80rem;
  font-size: 2.4rem;
  text-align: center;
  padding: 1rem;

  &-topbtngrp {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &-closeicon {
      margin: 1rem 1rem 1rem 0;
      height: 2rem;
      cursor: pointer;
    }
  }


  &-form {
    position: relative;
    display: flex;
    flex-direction: column;

    &-attachment,
    &-name,
    &-notes {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
    }

    &-name {
      width: 70%;
    }

    &-notes {
      width: 70%;
    }

    &-btngrp {
      position: relative;
      display: flex;
      justify-content: space-evenly;

      &-submitbtn {
        background-color: $btn-color-blue;
        height: 5rem;
        width: 15rem;
        border: none;
        border-radius: 0.2rem;
        font-size: 1.8rem;
        margin: 1rem 0;
        cursor: pointer;
      }

      &-submitbtn:active {
        background-color: $btn-color-green;
      }
    }


  }

  &-document {
    overflow-y: scroll;
    height: 55rem;
  }


}