.cl {
  position: relative;

  &-btngrp {
    position: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
    padding: 0 10rem;

    &-printbtn {
      position: relative;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
    }

    &-edit {
      position: relative;
      width: 18rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      margin: 0 0 0 5rem;
    }

    &-email {
      position: relative;
      width: 18rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      margin: 0 0 0 5rem;
    }

    &-creator {
      position: relative;
      width: 15.5rem;
      height: 4rem;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      margin: 0 0 0 auto;
    }

    &-printbtn:hover {
      background-color: #F0F0F0;
    }

    &-edit:hover {
      background-color: #F0F0F0;
    }

    &-creator:hover {
      background-color: #F0F0F0;
    }
  }


  &-container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 4rem 3rem;

    &-reports {
      position: relative;
      width: 45rem;
      min-width: 42rem;
      border: 0.1rem solid #000000;
      border-radius: .7rem;
      background-color: #D9D9D9;
      height: 55.3rem;
      overflow-y: auto;
      font-size: 1.4rem;
      margin-top: 4rem;

      &-title {
        position: relative;
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-list {
        position: relative;
        list-style: none;
        max-width: 45rem;

        &-item {
          margin-top: 1rem;
          cursor: pointer;
          background-color: #b5b5b5;
          height: 7rem;
          padding: 1.3rem 0 1.3rem 2rem;

          &-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-title {
              font-size: 1.5rem;
            }
          }
        }

        .selecteditem {
          background-color: #F0F0F0;
        }

        &-item:hover {
          background-color: #F0F0F0;
        }
      }
    }
  }
}