@import '../../sass/variables';

.si {
  height: 100rem;
  width: 100%;

  &-jobsearch {
    position: relative;
    margin: auto;
    top: 40rem;
    width: 50%;
    height: 20rem;
    display: flex;
    flex-direction: column;

    &-label {
      font-size: 1.8rem;
    }

    &-btn {
      position: relative;
      width: 50rem;
      height: 10rem;
      margin: auto;
      font-size: 2rem;
      border-radius: 0.5rem;
    }
  }

  &-jobslist {
    position: relative;
    width: 50%;
    height: 50rem;
    margin: auto;
    top: 40rem;
    list-style: none;
    // border: #a1a1a1 solid 0.1rem;

    &-item {
      text-align: center;
      font-size: 2rem;
      background-color: $btn-color-primary;
      margin: 1rem 0 1rem 0;
      height: 5rem;
      padding: 1.5rem 0 1.5rem 0;
      border-radius: 0.5rem;
    }
  }

  &-timesheet {
    position: relative;
    font-size: 1.8rem;
    padding: 2rem 0;

    &-header1 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2rem;

      &-col1 {
        position: relative;
        display: flex;
        flex-direction: column;
      }
    }

    &-header2 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2rem;

      &-col1 {
        position: relative;
        display: flex;
        flex-direction: row;
      }

      &-col2 {
        position: relative;
        display: flex;
        flex-direction: row;
      }
    }

    &-header3 {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      &-col1 {
        position: relative;
        display: flex;
        flex-direction: column;

        &-worktype {
          background-color: $btn-color-blue;
          cursor: pointer;
        }
      }

      &-col2 {
        position: relative;
        display: flex;
        flex-direction: column;

        &-date {
          margin-bottom: 2rem;
        }
      }
    }

    &-timemodal {
      position: relative;
      bottom: 20rem;
      left: 25rem;
      z-index: 99999;
      background-color: $btn-color-primary;
      border: 0.1rem #000000 solid;
      height: 50rem;
      width: 50rem;
      font-size: 2.4rem;
      text-align: center;


    }

    &-notesmodal {
      position: relative;
      bottom: 20rem;
      left: 25rem;
      z-index: 99999;
      background-color: $btn-color-primary;
      border: 0.1rem #000000 solid;
      height: 50rem;
      width: 50rem;
      font-size: 2.4rem;
      text-align: center;
    }

    &-signaturebtn {
      position: inherit;
      left: 80%;
      top: 50rem;
    }
  }

  &-nodata {
    position: relative;
    font-size: 4rem;
    color: #b5b3aa;
    text-align: center;
    top: 45rem;
  }

}