.employees {
  position: relative;
  font-size: 1.8rem;
  height: 95vh;
  width: 100vw;

  &-topcontainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem 0;
    justify-content: space-around;
    height: 3rem;
    margin: 2rem 0 0 0;

    &-filter {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-label {
        margin-right: 2rem;
      }

      &-selector {
        cursor: pointer;
        border-radius: 0.5rem;
        border: 0.1rem solid #000000;
        background-color: #b5b5b5;
      }
    }

    &-removebtn {
      position: relative;
      width: 25rem;
      height: 3rem;
      font-size: 1.8rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      // margin: 4rem 2rem 0 70%;

    }

    .remove-selected {
      background-color: #ff0000;
      color: #ffffff;
      border: none;
    }

    .remove-selected:hover {
      background-color: #00ff11;
      color: #000000;
      border: none;
    }

    &-removebtn:hover,
    &-removebtn:active {
      background-color: #F0F0F0;
    }

    &-addemployee {
      z-index: 9999;
      position: inherit;
      height: 3rem;
      width: 20rem;
      left: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      // top: 2rem;
      // left: 2%;
      cursor: pointer;

      &-text {
        position: relative;
        margin: 0 auto;
      }

      &-button {
        position: relative;
        height: 2rem;
        width: 2rem;
        margin: auto 0;

      }
    }

    .addemployeeclicked {
      cursor: not-allowed;
    }
  }



  &-wrapper {
    position: inherit;
    display: flex;
    flex-direction: row;
    height: 95vh;
    width: 100vw;
    padding: 2rem;

    &-workerlist {
      position: inherit;
      width: 30%;
      top: 10rem;
      border: 0.1rem solid #000000;
      border-radius: .7rem;
      background-color: #D9D9D9;
      height: 55.3rem;

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-list {
        position: relative;
        overflow-y: auto;
        height: 50rem;
        list-style: none;

        &-item {
          margin-top: 1rem;
          cursor: pointer;
          background-color: #b5b5b5;
          height: 5rem;
          padding: 1.3rem 0 1.3rem 2rem;
        }

        .selecteditem {
          background-color: #F0F0F0;
        }

        &-item:hover {
          background-color: #F0F0F0;
        }
      }
    }

    &-workerdetails {
      position: inherit;
      border: 0.1rem solid #000000;
      border-radius: 0.7rem;
      background-color: #D9D9D9;
      height: 65rem;
      width: 55%;
      display: flex;
      flex-direction: column;
      top: 10rem;
      left: 10%;

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-form {
        position: inherit;
        display: flex;
        flex-direction: column;
        overflow-y: auto;


        &-editbtn {
          width: 6rem;
          height: 3rem;
          font-size: 1.8rem;
          cursor: not-allowed;
          border-radius: 0.5rem;
          border: 0.1rem solid #000000;
          background-color: #b5b5b5;
          margin: 2rem 2rem 1rem auto;
        }

        &-editbtn:hover {
          background-color: #F0F0F0;
        }

        .editbtnactive {
          cursor: pointer;
        }

        &-row1 {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;
          padding: 0 3rem;

          &-firstName,
          &-lastName {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 40%;

            &-input {
              width: 100%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
            }
          }
        }

        &-row2 {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;
          padding: 0 3rem;

          &-middleInitial,
          &-class,
          &-last4SSN {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 20%;

            &-input {
              width: 100%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
            }
          }

          &-email {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 40%;

            &-input {
              width: 100%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
            }
          }
        }

        &-row3 {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;
          padding: 0 3rem;

          &-jobs {

            &-list {
              list-style: none;
              height: 35rem;
              overflow-y: auto;

              &-item {
                margin-top: 1rem;
                cursor: pointer;
                background-color: #b5b5b5;
                height: 5rem;
                padding: 1.3rem 0 1.3rem 2rem;
                border-radius: 0.7rem;
                width: 30rem;
              }

              &-item:hover {
                background-color: #F0F0F0;
              }
            }
          }

          &-verifyid {
            position: auto;
            display: flex;
            flex-direction: column;
            margin-left: auto;

            &-input {
              width: 80%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
              border-radius: 0.5rem;
              cursor: pointer;
            }
          }
        }

        &-btngroup {
          position: relative;
          display: flex;
          flex-direction: row;

          &-savebtn {
            width: 6rem;
            height: 3rem;
            font-size: 1.8rem;
            cursor: pointer;
            border-radius: 0.5rem;
            border: 0.1rem solid #000000;
            background-color: #b5b5b5;
            margin: 4rem 2rem 0 2rem;

          }

          &-savebtn:hover,
          &-savebtn:active {
            background-color: #F0F0F0;
          }
        }
      }
    }
  }
}