.confirmuser {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;



  &-authform {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 700rem;
    width: 100rem;
    padding: 2rem;

    &-codelabel {
      margin-top: 20rem;
    }

    &-codeinputs {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      width: 30%;

      &-input {
        height: 4rem;
        width: 4rem;
        text-align: center;
        border-radius: 5px;
        margin-top: 2rem;
      }

      &-emailinput {
        height: 3rem;
        width: 30rem;
        text-align: center;
        border-radius: 5px;
        margin-top: 2rem;
      }
    }

    &-submitbtn {
      position: relative;
      width: 25rem;
      height: 3rem;
      font-size: 1.8rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      margin: 3rem auto;
    }
  }



}