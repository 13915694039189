.worksites {
  position: relative;
  font-size: 1.8rem;
  height: 95vh;
  width: 100vw;

  &-jobratesbtn {
    position: relative;
    width: 6rem;
    height: 3rem;
    font-size: 1.8rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 0.1rem solid #000000;
    background-color: #b5b5b5;
  }

  &-btngrp {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0;

    &-filter {
      margin: 0 4rem;

      &-label {
        margin-right: 1rem;
      }
    }

    &-removebtn {
      position: relative;
      width: 25rem;
      height: 3rem;
      font-size: 1.8rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      // margin: 4rem 2rem 0 70%;
    }

    .remove-selected {
      background-color: #ff0000;
      color: #ffffff;
      border: none;
    }

    .remove-selected:hover {
      background-color: #00ff11;
      color: #000000;
      border: none;
    }

    &-removebtn:hover,
    &-removebtn:active {
      background-color: #F0F0F0;
    }

    &-addworksite {
      z-index: 9999;
      position: relative;
      width: 20rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;


      &-text {
        position: relative;
        left: 20%;
        font-size: 1.5rem;
        margin: 0;
      }

      &-button {
        position: relative;
        height: 2rem;
        width: 2rem;
        margin-left: 3rem;
      }

      &-btngroup {
        position: relative;
        display: flex;
        flex-direction: row;

        &-savebtn {
          width: 6rem;
          height: 3rem;
          font-size: 1.8rem;
          cursor: pointer;
          border-radius: 0.5rem;
          border: 0.1rem solid #000000;
          background-color: #b5b5b5;
          margin: 4rem 2rem 0 2rem;

        }

        &-savebtn:hover,
        &-savebtn:active {
          background-color: #F0F0F0;
        }
      }
    }
  }



  &-wrapper {
    position: inherit;
    display: flex;
    flex-direction: row;
    height: 95vh;
    width: 100vw;
    padding: 2rem;

    &-sitelist {
      position: inherit;
      width: 30%;
      top: 10rem;
      border: 0.1rem solid #000000;
      border-radius: .7rem;
      background-color: #D9D9D9;
      height: 55.3rem;

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-list {
        position: relative;
        overflow-y: auto;
        height: 50rem;
        list-style: none;

        &-item {
          margin-top: 1rem;
          cursor: pointer;
          background-color: #b5b5b5;
          height: 5rem;
          padding: 1.3rem 0 1.3rem 2rem;
        }

        .selecteditem {
          background-color: #F0F0F0;
        }

        &-item:hover {
          background-color: #F0F0F0;
        }
      }
    }

    &-sitedetails {
      position: inherit;
      border: 0.1rem solid #000000;
      border-radius: 0.7rem;
      background-color: #D9D9D9;
      height: 70rem;
      width: 60%;
      display: flex;
      flex-direction: column;
      top: 10rem;
      left: 10%;

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-editbtn {
        width: 6rem;
        height: 3rem;
        font-size: 1.8rem;
        cursor: not-allowed;
        border-radius: 0.5rem;
        border: 0.1rem solid #000000;
        background-color: #b5b5b5;
        margin: 2rem 2rem 1rem auto;
      }

      &-editbtn:hover {
        background-color: #F0F0F0;
      }

      .editbtnactive {
        cursor: pointer;
      }

      &-row1 {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 2.5rem 0;
        padding: 0 3rem;

        &-agency {
          width: 30rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            min-height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }

        &-name {
          width: 35rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            min-height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }

        &-location {
          width: 30rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            min-height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }
      }

      &-row2 {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 0;
        padding: 0 3rem;


        &-taskcompletedate {
          width: 20rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }

        &-contractno {
          width: 20rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }

        &-location {
          width: 30rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            min-height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }

      }

      &-row3 {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 0;
        padding: 0 3rem;

        &-foreman {
          position: relative;
          margin-bottom: auto;
          width: 20rem;
          height: 5.5rem;

          &-text {
            margin-top: 1rem;
            height: 3rem;
            border-radius: 0.5rem;
            background-color: #b5b5b5;
            padding: 0.5rem 0
          }
        }

        &-employees {

          &-list {
            list-style: none;
            height: 30rem;
            overflow-y: auto;

            &-item {
              margin-top: 1rem;
              cursor: pointer;
              background-color: #b5b5b5;
              height: 5rem;
              padding: 1.3rem 0 1.3rem 2rem;
              border-radius: 0.7rem;
              width: 30rem;
            }

            &-item:hover {
              background-color: #F0F0F0;
            }
          }
        }
      }

      &-addworksite {
        &-row1 {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 2.5rem 0;
          padding: 0 3rem;

          &-job {
            position: inherit;
            display: flex;
            flex-direction: column;
            width: 50%;

            &-list {
              width: 100%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
              border-radius: 0.5rem;
              margin: 2rem 0;
            }
          }

          &-foreman {
            position: inherit;
            display: flex;
            flex-direction: column;
            width: 30%;

            &-list {
              width: 100%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
              border-radius: 0.5rem;
              margin: 2rem 0;
            }
          }

        }

        &-row2 {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 2.5rem 0;
          padding: 0 3rem;

          &-employees {
            position: inherit;
            display: flex;
            flex-direction: column;
            width: 50%;

            &-list {
              width: 100%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
              border-radius: 0.5rem;
              margin: 2rem 0;
            }

            &-addemployees {
              overflow-y: scroll;
              height: 30rem;

              &-list {
                list-style: none;

                &-item {
                  position: relative;
                  display: flex;

                  background-color: #b5b5b5;
                  margin: 1rem 0;
                  height: 3rem;
                  padding: 0.5rem 0;
                  border-radius: 0.5rem;

                  &-removebtn {
                    margin: 0 2rem 0 auto;

                    &-icon {
                      height: 2rem;
                      width: 2rem;
                      color: #636363;
                      cursor: pointer;
                    }

                    &-icon:hover {
                      height: 2rem;
                      width: 2rem;
                      color: #878686;
                      cursor: pointer;
                    }

                    .removebtn-disabled {
                      pointer-events: none;
                    }
                  }
                }
              }
            }
          }

          &-archive {
            margin-right: 2rem;

            &-label {
              position: inherit;
              display: flex;
              flex-direction: column;
              margin: 0 4rem 1rem 0;
            }

            &-input {
              width: 80%;
              height: 4rem;
              font-size: 1.8rem;
              border: none;
              border-radius: 0.5rem;
              cursor: pointer;
            }
          }
        }

        &-btngroup {
          position: relative;
          display: flex;
          flex-direction: row;

          &-savebtn {
            width: 6rem;
            height: 3rem;
            font-size: 1.8rem;
            cursor: pointer;
            border-radius: 0.5rem;
            border: 0.1rem solid #000000;
            background-color: #b5b5b5;
            margin: 0 2rem 0 2rem;

          }

          &-savebtn:hover,
          &-savebtn:active {
            background-color: #F0F0F0;
          }
        }
      }
    }
  }

  &-jobsratesbtn {
    margin: 2rem 0 0 2rem;
    background-color: #b5b5b5;
    border: none;
    height: 3rem;
    border-radius: 0.5rem;
    width: 20rem;
    cursor: pointer;
  }

  &-jobsratesbtn:hover,
  &-jobsratesbtn:active {
    background-color: #F0F0F0;
  }
}