@media print {
  .pagebreak {
    page-break-before: always;
  }

  .dailyworkreport-body-employeecontainer-table {
    margin-top: 10px;
  }

  .dailyworkreport-body-employeenotescontainer-table {
    margin-top: 10px;
  }

  .dailyworkreport-body-materials-table {
    margin-top: 10px;
  }

  .dailyworkreport-remarks {
    margin-top: 10px;
  }
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: auto;
  }

  .dailyworkreport-body-employeecontainer-table {
    margin-top: 10px;
  }

  .dailyworkreport-body-employeenotescontainer-table {
    margin-top: 10px;
  }

  .dailyworkreport-body-materials-table {
    margin-top: 10px;
  }

  .dailyworkreport-remarks {
    margin-top: 10px;
  }

}

// @media print {
//   .last-approvals:after {
//     display: block;
//     content: "";
//     // margin-bottom: 594mm;
//     margin-bottom: 80%;
//     /* must be larger than largest paper size you support */
//   }
// }