@import '../../sass/variables';

.checklist {
  position: relative;
  overflow-y: auto;
  height: 100%;
  // color: #ffffff;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;

  &-controls {
    position: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 7rem;
    margin: 0;
  }



  &-topform {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &-checklisttype {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      // margin-bottom: 2rem;

      &-select {
        height: 3rem;
        border: none;
        font-size: 1.8rem;
        text-align: center;
      }
    }

    &-inputgroup {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      margin-top: 2rem;

      &-label {
        margin-right: 2rem;
        width: 10rem;
      }

      &-input {
        width: 80%;
        padding: 1rem;
      }
    }
  }

  &-nonconformances {
    padding: 2rem;

    &-inputgroup {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      margin-top: 2rem;

      &-label {
        margin-right: 2rem;
        width: 10rem;
        font-size: 1.3rem;
      }

      &-input {
        width: 80%;
        padding: 1rem;
      }
    }
  }

  &-additionalremarks {
    padding: 2rem;

    &-inputgroup {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      margin-top: 2rem;

      &-label {
        margin-right: 2rem;
        width: 10rem;
      }

      &-input {
        width: 80%;
        padding: 1rem;
      }
    }
  }

  &-fieldtasks {
    position: relative;
    padding: 3rem;

    &-items {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      &-qnumber {
        width: 2.5rem;
      }

      &-question {
        width: 75%;
        margin-right: auto;
      }

      &-answer {

        &-label {
          margin-right: 1rem;
        }
      }
    }
  }

  &-signatures {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &-row {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;

      &-name {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;

        width: 50rem;

        &-label {
          margin-right: 1rem;
          width: 10rem;
          font-size: 1.5rem;
        }

        &-input {
          width: 100%;
          padding: 1rem;

        }
      }

      &-title {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 40rem;

        &-label {
          width: 5rem;
        }

        &-input {
          width: 100%;
          padding: 1rem;
        }
      }

      &-sig {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
        width: 50rem;

        &-label {
          margin-right: 1rem;
          width: 10rem;
          font-size: 1.5rem;
        }

        &-input {
          width: 100%;
          padding: 1rem;

        }
      }

      &-date {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
        width: 50rem;

        &-label {
          margin-right: 1rem;
          width: 5rem;
          font-size: 1.5rem;
        }

        &-input {
          width: 100%;
          padding: 1rem;

        }
      }


    }

    &-inputgroup {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 3rem;
      margin-top: 2rem;
    }
  }
}