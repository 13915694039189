@import '../../../sass/variables';

.userlogin {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 1.8rem;
  background-color: #e0dfda;
  padding: 0 20rem;

  &-header {
    position: relative;
    text-align: center;
    margin: 5rem auto;

    &-title {
      margin-bottom: 2rem;
    }
  }

  &-input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 20rem;

    &-label {
      margin: 1rem auto 0 auto;
    }

    &-input {
      width: 100%;
      height: 5rem;
      margin: 1rem auto 0 auto;
      border: none;
      background-color: #ffffff;
      border-radius: 0.5rem;
      font-size: 1.8rem;
    }
  }

  &-submit {
    position: relative;
    background-color: $btn-color-blue;
    height: 5rem;
    width: 15rem;
    border: none;
    border-radius: 0.7rem;
    font-size: 1.8rem;
    cursor: pointer;
  }

  &-submit:active {
    background-color: #e0dfda;
  }

  &-forgotcredentials {
    position: inherit;
    display: flex;
    flex-direction: row;

    &-btn {
      position: inherit;
      left: 10rem;
      height: 3rem;
      width: 15rem;
      margin: 2rem 0 0 0;
      border-radius: .7rem;
      cursor: pointer;
    }

    &-btn:active {
      background-color: #e0dfda;
    }
  }
}