@import '../../../sass/variables';

.jobs {
  position: relative;
  font-size: 1.8rem;
  height: 100vh;
  width: 100vw;

  &-filtercontainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem 0;
    justify-content: space-around;
    height: 3rem;
    margin: 2rem 0 0 0;

    &-removebtn {
      position: relative;
      width: 25rem;
      height: 3rem;
      font-size: 1.8rem;
      cursor: pointer;
      border-radius: 0.5rem;
      border: 0.1rem solid #000000;
      background-color: #b5b5b5;
      // margin: 4rem 2rem 0 70%;

    }

    .remove-selected {
      background-color: #ff0000;
      color: #ffffff;
      border: none;
    }

    .remove-selected:hover {
      background-color: #00ff11;
      color: #000000;
      border: none;
    }

    &-removebtn:hover,
    &-removebtn:active {
      background-color: #F0F0F0;
    }


    &-addjob {
      z-index: 9999;
      position: inherit;
      height: 3rem;
      width: 20rem;
      left: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      // top: 2rem;
      // left: 2%;

      cursor: pointer;

      &-text {
        position: relative;
        margin: 0 auto;
      }

      &-button {
        position: relative;
        height: 2rem;
        width: 2rem;
        margin: auto 0;
      }
    }

    &-filter {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-label {
        margin-right: 2rem;
      }

      &-selector {
        cursor: pointer;
        border-radius: 0.5rem;
        border: 0.1rem solid #000000;
        background-color: #b5b5b5;
      }
    }


  }

  &-costcodesbtn {
    margin: 2rem 0;
    padding: 0 5rem;

    &-link {
      cursor: pointer;
      color: #000000;
      text-decoration: none;
      border: #000000 0.1rem solid;
      border-radius: 0.5rem;
      width: 100%;
      background-color: #b5b5b5;
    }
  }

  &-removebtn {
    position: relative;
    width: 25rem;
    height: 3rem;
    font-size: 1.8rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 0.1rem solid #000000;
    background-color: #b5b5b5;
    margin: 4rem 2rem 0 70%;
  }

  .remove-selected {
    background-color: #ff0000;
    color: #ffffff;
    border: none;
  }

  .remove-selected:hover {
    background-color: #00ff11;
    color: #000000;
    border: none;
  }

  &-removebtn:hover,
  &-removebtn:active {
    background-color: #F0F0F0;
  }

  &-addjob {
    z-index: 9999;
    position: inherit;
    height: 3rem;
    width: 20rem;
    left: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    top: 2rem;
    left: 2%;
    cursor: pointer;

    &-text {
      position: relative;
      margin: 0 0 0 auto;
      left: 20%;
      cursor: pointer;
    }

    &-button {
      position: relative;
      height: 3rem;
      width: 3rem;
      margin-left: auto;
      cursor: pointer;
    }
  }

  &-jobs {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 95vh;
    width: 100vw;
    padding: 2rem;



    &-jobslist {
      position: inherit;
      width: 30%;
      top: 10rem;
      border: 0.1rem solid #000000;
      border-radius: .7rem;
      background-color: #D9D9D9;
      height: 55.3rem;

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-wrapper {
        position: relative;
        overflow-y: auto;
        height: 50rem;



        &-ul {
          list-style: none;

          &-item {
            margin-top: 1rem;
            cursor: pointer;
            background-color: #b5b5b5;
            height: 5rem;
            padding: 1.3rem 0 1.3rem 2rem;
          }

          .selecteditemactive {
            background-color: #F0F0F0;
          }

          .itemactive:hover {
            background-color: #F0F0F0;
          }
        }
      }
    }

    &-jobdetails {
      position: relative;
      border: 0.1rem solid #000000;
      border-radius: 0.7rem;
      background-color: #D9D9D9;
      height: 100rem;
      width: 55%;
      display: flex;
      flex-direction: column;
      top: 10rem;
      left: 10%;
      overflow-y: scroll;
      // margin-bottom: 2rem;

      &-editbtn {
        position: relative;
        margin: 2rem 2rem 0 auto;
        font-weight: 400;

        &-btn {
          width: 6rem;
          height: 3rem;
          font-size: 1.8rem;
          cursor: pointer;
          border-radius: 0.5rem;
          border: 0.1rem solid #000000;
          background-color: #b5b5b5;
        }

        &-btn:hover {
          background-color: #F0F0F0;
        }
      }



      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-row1 {
        position: inherit;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 2rem 0;

        &-jobname {
          position: inherit;
          display: flex;
          flex-direction: column;
          width: 40%;

          &-input {
            width: 100%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

        &-agencyname {
          position: inherit;
          display: flex;
          flex-direction: column;
          width: 50%;

          &-input {
            width: 100%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }
      }

      &-row2 {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 2rem 0;
        justify-content: space-around;

        &-contractno {
          position: inherit;
          display: flex;
          flex-direction: column;
          width: 20%;

          &-input {
            width: 100%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

        &-laborticketabv {
          position: inherit;
          display: flex;
          flex-direction: column;

          &-input {
            width: 90%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

        &-location {
          position: inherit;
          display: flex;
          flex-direction: column;
          width: 40%;

          &-input {
            width: 100%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

      }

      &-row3 {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 2rem 0;
        justify-content: space-between;
        padding: 0 2rem;

        &-docprefix {
          position: inherit;
          display: flex;
          flex-direction: column;
          width: 50%;

          &-input {
            width: 80%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

        &-taskcompletiondate {
          position: inherit;
          display: flex;
          flex-direction: column;

          &-input {
            width: 80%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

        &-workssiteassigned {
          position: inherit;
          display: flex;
          flex-direction: column;

          &-text {
            position: relative;
            width: 40%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
            background-color: #ffffff;
            text-align: center;
            padding: 1rem 0;
          }

          &-text:hover {
            background-color: #F0F0F0;
          }
        }

      }

      &-row4 {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 2rem 0;
        justify-content: space-between;

        &-contractstartdate {
          position: inherit;
          display: flex;
          flex-direction: column;
          margin-left: 2rem;

          &-input {
            width: 80%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
          }
        }

        &-verifyid {
          position: inherit;
          display: flex;
          flex-direction: column;
          margin-right: 4rem;

          &-input {
            width: 80%;
            height: 4rem;
            font-size: 1.8rem;
            border: none;
            border-radius: 0.5rem;
            cursor: pointer;
          }
        }
      }

      &-row5 {
        position: relative;
        display: flex;
        flex-direction: flex-start;

        &-markups {
          position: inherit;
          display: flex;
          flex-direction: column;
          margin-left: 2rem;
          width: 100%;

          &-table {
            width: 50%;

            &-caption {
              text-align: left;
              margin-bottom: 1rem;

            }

            &-head {
              &-row {
                font-size: 1.5rem;

                &-header {
                  text-align: left;
                }

                &-headerpercent {
                  text-align: left;
                  width: 10rem;
                }

                &-addmarkup {
                  text-align: left;

                  &-btn {
                    position: relative;
                    display: flex;
                    padding-top: 1.5rem;
                    justify-content: space-between;
                    cursor: pointer;
                    width: 12rem;

                    &-icon {
                      margin: 0.5rem 0.5rem 0 0;
                    }
                  }

                  .addmarkup-disabled {
                    pointer-events: none;
                  }
                }
              }
            }

            &-body {
              &-row {
                &-element {

                  &-selector {
                    width: 100%;
                    height: 4rem;
                    font-size: 1.8rem;
                    border: none;
                    border-radius: 0.5rem;
                    cursor: pointer;
                  }

                  &-input {
                    width: 50%;
                    height: 4rem;
                    font-size: 1.8rem;
                    border: none;
                    border-radius: 0.5rem;
                    cursor: pointer;
                  }

                  &-minusicon {
                    height: 2rem;
                    width: 2rem;
                    color: #636363;
                    cursor: pointer;
                  }

                  &-minusicon:hover {
                    height: 2rem;
                    width: 2rem;
                    color: #878686;
                    cursor: pointer;
                  }

                  .minusicon-disabled {
                    pointer-events: none;
                  }
                }
              }
            }
          }


        }

        &-percentage {
          position: inherit;
          display: flex;
          flex-direction: column;
          margin-left: 2rem;



        }
      }


      &-btngroup {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8rem;

        &-savebtn {
          width: 6rem;
          height: 3rem;
          font-size: 1.8rem;
          cursor: pointer;
          border-radius: 0.5rem;
          border: 0.1rem solid #000000;
          background-color: #b5b5b5;
          margin: auto 2rem;

        }

        &-savebtn:hover,
        &-savebtn:active {
          background-color: #F0F0F0;
        }
      }
    }
  }
}