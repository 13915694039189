.camera {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 100vh;
  width: 100vw;


  &-thumbnailcontainer {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #362F2D;
    overflow-x: auto;
    white-space: nowrap;

    height: 10rem;


    &-img {
      height: 8rem;
      width: 10rem;
      margin: 2px 0 2px 2px;
    }
  }

  &-form {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-jobscontainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-jobselector {
        margin: 2rem 3rem 0 2rem;
      }

      &-shiftselector {
        margin: 2rem auto 0 2rem;
      }
    }

    &-camcontainer {
      padding-top: 3rem;
      margin: 0 auto;
      height: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-btncontainer {
      width: 60rem;
      margin: 1rem auto;
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &-capturebtn,
      &-retakebtn {
        height: 3rem;
        width: 12rem;
        background-color: #30ABE6;
        color: #ffffff;
        border: none;
        border-radius: 5px;
      }

      &-capturebtn {}
    }

    &-textcontainer {
      width: 60rem;
      margin: 2rem auto 2rem auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-textlabel {
        margin-right: auto;

      }

      &-textarea {
        width: 100%;
        height: 5rem;
        border-radius: 5px;
      }

    }

    &-submitbtn {
      margin: 1rem auto 0 auto;
      width: 50%;
      height: 3rem;
    }
  }
}