@import '../../sass/variables';

.button1 {
  background-color: $btn-color-blue;
  height: 5rem;
  width: 15rem;
  border: none;
  border-radius: 0.2rem;
  font-size: 1.8rem;
}

.button1:active {
  background-color: $btn-color-green;
}