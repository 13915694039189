@import '../../sass/variables';

.workersWr {
  position: relative;
  font-size: 1.8rem;

  &-list {
    position: relative;
    list-style: none;

    &-item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      background-color: $btn-color-primary;
      height: 5rem;
      margin-bottom: 2rem;
      cursor: pointer;

      &-name {
        width: 25rem;
        margin-right: 5rem;
        padding: 0.5rem 0 0 1.5rem;
        font-size: 1.4rem;
      }

      &-last4ssn {
        width: 5rem;
        margin-right: 5rem;
        text-align: center;
        padding-top: 1.5rem;
      }

      &-class {
        width: 12rem;
        margin-right: 5rem;
        text-align: center;
        padding-top: 1.5rem;
      }

      &-timein,
      &-doubletime {
        position: relative;
        width: 10rem;
        margin-right: 5rem;
        background-color: $btn-color-magenta;
        text-align: center;

        &-time {
          position: relative;
          top: 1.3rem;
          font-size: 1.5rem;
        }

        &-placeholder {
          position: relative;
          top: 1.5rem;
        }
      }

      &-notes {
        width: 10rem;
        text-align: center;
        padding-top: 1.5rem;
        background-color: $btn-color-blue;
        cursor: pointer;
      }

      .employeein,
      .employeedt,
      .employeenotes {
        background-color: $btn-color-green;
      }
    }
  }
}