.costcodes {
  position: relative;
  font-size: 1.5rem;
  height: 100vh;
  width: 100vw;
  padding: 2rem;

  &-spreadsheet {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    &-header {
      margin-bottom: 1rem;
    }

    &-label {
      display: block;
      width: 50rem;
      margin: 0.5rem 1rem 0.5rem 0;
    }

    &-input {
      margin-bottom: 1rem;
    }

    &-submit {
      max-width: 20rem;
      height: 5rem;
      border-radius: 0.5rem;
      border: #000000 solid 0.1rem;
      cursor: pointer;
    }
  }

  &-individual {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    &-header {
      margin-bottom: 1rem;
    }

    &-codegroup {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;

      &-labor,
      &-costcode,
      &-description {
        margin: 1rem 0 1rem 0.5rem;

        &-label {
          display: block;
          width: 10rem;
        }

        &-input {
          width: 60rem;
          padding: 0 0.1rem;
        }
      }

      &-description {

        &-input {
          height: 10rem;
          padding: 0.5rem;
        }
      }
    }



    &-submit {
      max-width: 20rem;
      height: 5rem;
      border-radius: 0.5rem;
      border: #000000 solid 0.1rem;
      cursor: pointer;
    }

    &-delete {
      max-width: 20rem;
      height: 5rem;
      border-radius: 0.5rem;
      border: #ffffff solid 0.1rem;
      cursor: pointer;
      background-color: red;
      color: #ffffff;
    }
  }

  &-bottomwrapper {
    width: 100%;
    height: 30rem;
    overflow-y: scroll;

    &-codestable {
      width: 100%;
      // height: 35rem;
      // margin-bottom: 5rem;

      &-head {
        background-color: #D9D9D9;
      }

      &-body {

        .selectedRow {
          background-color: red;
          color: #ffffff
        }

        &-row {
          // margin: 0.5rem 0 0.5rem 0;
          background-color: #b5b5b5;
          height: 3rem;

          .icons {
            width: 10rem;
          }

          &-item {
            text-align: center;

            &-ccedit {
              width: 7rem;
              height: 2.5rem;
              border-radius: 0.4rem;
              border: transparent;
              padding: 0 2px 0 2px;
            }

            &-lcedit {
              width: 7rem;
              height: 2.5rem;
              border-radius: 0.4rem;
              border: transparent;
              padding: 0 2px 0 2px;
            }

            &-descedit {
              width: 80%;
              height: 2.5rem;
              border-radius: 0.4rem;
              border: transparent;
              padding: 0 2px 0 2px;
            }

            &-iconwrapper {
              position: relative;
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              &-delete,
              &-edit {
                cursor: pointer
              }

              &-delete {
                // border: 0.1rem solid black;
                border-radius: 50px;
                height: 15px;

              }

              &-delete:active,
              &-delete:hover {
                border: 0.1rem solid #ffffff;
                border-radius: 50px;
                background-color: red;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

}