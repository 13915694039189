@import '../../sass/variables';

.facialverify {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 2rem;

  &-employeeinfo {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    // margin: 2rem auto 3rem auto;

    &-dropdown {
      position: relative;
      display: flex;
      // align-items: center;

      &-label {
        margin-right: 1rem;
        margin-bottom: 12rem;
      }

      &-selector {
        margin-bottom: auto;
      }
    }

    &-details {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;


      &-name,
      &-class {
        position: relative;
        display: flex;
        margin-right: auto;

        &-label {
          margin-right: 3rem;
        }
      }

      &-image {
        position: relative;
        display: flex;
        margin-right: auto;

        &-label {
          margin-right: 1%;
        }

        &-container {
          width: 7rem;
          height: 7rem;


          &-wrapper {
            width: 100%;
            height: 100%;
            position: relative;

            &-img {
              width: 160px;
              height: 110px;
              position: absolute;
              top: 0px;
              left: 0px;
            }

            &-canvas {
              // width: 100%;
              // height: 100%;
              width: 160px;
              height: 110px;
              position: absolute;
              top: -20px;
              left: 10px;
            }
          }
        }


      }
    }
  }

  &-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70rem;

    &-group {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-container {
        height: 30rem;
        width: 40.5rem;
        margin: 0 auto;
        margin-top: 10rem;
        border: 0.2rem solid #000000;
        border-radius: 10px;

        &-wrapper {
          width: 100%;
          height: 100%;
          position: relative;

          &-imgviewer {
            height: 100%;
            width: 100%;
          }

          &-canvas {
            width: 100%;
            height: 100%;
            // width: 160px;
            // height: 110px;
            position: absolute;
            top: -45px;
            left: -15px;
          }
        }
      }

      &-capturebtn {
        height: 3rem;
        width: 12rem;
        background-color: #30ABE6;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        margin-top: 3rem;
      }

      &-retakegrp {
        position: relative;
        display: flex;
        justify-content: space-evenly;

        &-retakebtn {
          height: 3rem;
          width: 12rem;
          background-color: #30ABE6;
          color: #ffffff;
          border: none;
          border-radius: 5px;
          margin-top: 3rem;
        }

        &-savebtn {
          height: 3rem;
          width: 12rem;
          background-color: $btn-color-green;
          color: #000000;
          border: none;
          border-radius: 5px;
          margin-left: 5rem;
          margin-top: 3rem;
        }
      }

      &-verified {
        background-color: #616161;
        border-radius: 5px;
        color: #ffffff;
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        margin-top: 2rem;
        height: 5rem;
        padding: 9px 0;
      }

      &-unverified {
        background-color: #616161;
        border-radius: 5px;
        color: #ff0000;
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        margin-top: 2rem;
        height: 5rem;
        padding: 9px 0;
      }

    }
  }
}