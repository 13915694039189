@import '../../sass/variables';

.header {
  position: relative;
  font-size: 1.8rem;
  padding-top: 2rem;

  &-divider {
    position: inherit;
    border-top: 0.5px solid #000000;
    margin-bottom: 1rem;
    // width: 100vw;
    left: 0;
    right: 0;

    &-icon {
      margin-left: 95%;
      margin-top: 1rem;
    }
  }

  &-1 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &-col1 {
      position: relative;
      display: flex;
      flex-direction: column;

    }

    &-col3 {
      position: relative;
      display: flex;
      flex-direction: column;

    }
  }

  &-2 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &-col1 {
      position: relative;
      display: flex;
      flex-direction: column;

      &-label {
        margin-bottom: 1rem;

        &-add-icon {
          margin-left: 1rem;
        }
      }

      &-inputwrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;


        &-input {
          width: 30rem;
          margin-right: 2rem;
          border-radius: 0.5rem;
          padding: 0 0.5rem;
        }

        &-removeicon {
          border-radius: 5rem;
          border: #000000 2px solid;
          padding: 5px;
        }
      }
    }


    &-col2 {
      position: relative;
      display: flex;
      flex-direction: column;

      &-headers {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        &-label {
          margin-right: 1rem;
        }
      }

    }

    &-col2checklist-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      top: 5rem;
    }

    &-col2checklist {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      &-location {
        width: 25rem;
        margin-bottom: 3rem;
        padding: 0 1rem;
      }
    }
  }

  &-3 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;


    &-col1 {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      &-worktype {
        background-color: $btn-color-blue;
        cursor: pointer;
        width: 46%;
      }
    }

    &-col2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &-date {
        margin-bottom: 2rem;
        margin-left: auto;

        &-checklist {
          position: relative;
          bottom: 10rem;
          margin-bottom: 2rem;
        }

        &-picker {
          position: relative;
          margin-left: 2rem;
          height: 3rem;
          width: 15rem;
          font-size: 1.8rem;
          border: 0.1rem #000000 solid;
          border-radius: 0.4rem;

          &-checklist {
            margin-left: 2rem;
          }
        }
      }
    }
  }
}