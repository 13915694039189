@import '../../../sass/variables';

.agency {
  position: relative;
  font-size: 1.8rem;
  height: 95vh;
  width: 100vw;

  &-removebtn {
    position: relative;
    width: 25rem;
    height: 3rem;
    font-size: 1.8rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 0.1rem solid #000000;
    background-color: $btn-admin-primary;
    color: #ffffff;
    margin: 4rem 2rem 0 70%;
  }

  .remove-selected {
    background-color: #ff0000;
    color: #ffffff;
    border: none;
  }

  .remove-selected:hover {
    background-color: #00ff11;
    color: #000000;
    border: none;
  }

  &-removebtn:hover,
  &-removebtn:active {
    background-color: $btn-admin-secondary;
  }

  &-wrapper {
    position: inherit;
    display: flex;
    flex-direction: row;
    height: 95vh;
    width: 100vw;
    padding: 2rem;

    &-agencylist {
      position: inherit;
      width: 30%;
      top: 10rem;
      border: 0.1rem solid #000000;
      border-radius: .7rem;
      background-color: #D9D9D9;
      height: 55.3rem;

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-list {
        position: relative;
        overflow-y: auto;
        height: 50rem;
        list-style: none;

        &-item {
          margin-top: 1rem;
          cursor: pointer;
          background-color: #b5b5b5;
          height: 5rem;
          padding: 1.3rem 0 1.3rem 2rem;
        }

        .itemselected {
          background-color: #F0F0F0;
        }

        &-item:hover {
          background-color: #F0F0F0;
        }
      }
    }
  }

  &-agencyedit {
    position: inherit;
    border: 0.1rem solid #000000;
    border-radius: 0.7rem;
    background-color: #ffffff;
    height: 55rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    top: 10rem;
    left: 10%;
    display: flex;
    flex-direction: column;

    &-create {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      &-editname {
        position: inherit;
        height: 4rem;
        width: 80%;
        margin: 0 auto 0 2rem;
        font-size: 1.8rem;
        padding: 1rem 0 1rem 0;
        // text-align: center;
        background-color: #cbcbcb;
        border-radius: 0.5rem;
      }

      &-title {
        text-align: center;
        height: 5rem;
        background-color: #b5b5b5;
        padding: 1.3rem 0 1.3rem 0;
      }

      &-inputlabel {
        position: inherit;
        margin: 2rem auto 1rem 2rem;
      }

      &-input {
        position: inherit;
        height: 4rem;
        width: 80%;
        margin: 0 auto 0 2rem;
        font-size: 1.8rem;
        border-radius: 0.5rem;
        // text-align: center;
      }

      &-input:focus {
        background-color: #cbcbcb;
        border: #b5b5b5 solid 0.2rem;
      }

      &-btngroup {
        position: relative;
        display: flex;
        flex-direction: row;

        &-savebtn {
          width: 6rem;
          height: 3rem;
          font-size: 1.8rem;
          cursor: pointer;
          border-radius: 0.5rem;
          border: 0.1rem solid #000000;
          background-color: #b5b5b5;
          margin: 4rem 2rem 0 2rem;

        }

        &-savebtn:hover,
        &-savebtn:active {
          background-color: #F0F0F0;
        }
      }



    }

  }


}