.emailreports {
  height: 5rem;
  padding: 0 5rem;
  margin: 5rem 0 18rem 0;

  &-form {
    position: relative;
    display: flex;
    flex-direction: column;

    &-grp {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      // width: 35%;
      width: 100%;

      &-label {
        margin-right: 2rem;
        width: 15rem;
      }

      &-input {
        height: 3rem;
        width: 50rem;
        margin-right: auto;
        font-size: 1.5rem;
        border-radius: 0.3rem;
        padding: 0 0.5rem;
      }

      &-textarea {
        height: 10rem;
        width: 50rem;
        margin-right: auto;
        font-size: 1.5rem;
        border-radius: 0.3rem;
        padding: 0 0.5rem;
      }


    }

    .emailbody {
      margin: 2rem 0;
    }

    &-sendbtn {
      color: #000000;
      width: 15rem;
      height: 3rem;
      padding: 0.3rem 0 0.3rem 0;
      text-align: center;
      border: 0.1rem solid #000000;
      border-radius: 0.5rem;
      background-color: #b5b5b5;
      cursor: pointer;
      margin-left: auto;
    }

    &-sendbtn:hover {
      background-color: #F0F0F0;
    }
  }
}